@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  list-style: none;
  text-decoration: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: bold;
}

